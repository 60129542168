"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultUserSettings = void 0;
var lexorank_1 = require("lexorank");
exports.defaultUserSettings = {
    alwaysOnTop: true,
    alwaysOnTopInBreak: false,
    alwaysShowKeepMeOffMyPhone: true,
    applicationTracking: false,
    coachIntroOutro: true,
    coachTaskTimeReminders: true,
    coachDistractionNudges: true,
    coachEnabled: true,
    coachNudgeWhenIdle: true,
    coachVolume: 0.7,
    debugPlayer: false,
    defaultTaskTime: 25 * 60,
    defaultPlaylist: 'd7e28840-c472-4231-b973-ee6e81165690',
    dismissedDownloadDesktopModal: false,
    dismissedLinearModal: false,
    dismissedOnboardingModal: false,
    dismissedSlackModal: false,
    dndMode: false,
    enableBuddySessions: true,
    encouragementEnabled: true,
    musicEnabled: true,
    musicVolume: 0.7,
    videoEnabled: false,
    numberOfSeenSessions: 0,
    completedFirstFlowSession: false,
    completedFirstBreak: false,
    playSoundsOnChatMessage: true,
    taskQuickAdd: true,
    taskQuickAddShortcut: 'CommandOrControl+Shift+C',
    togglePlayerExpanded: true,
    togglePlayerExpandedShortcut: 'Alt+Shift+M',
    useFlowmeter: true,
    useFlowSessionNotification: true,
    unreadMessageReminderEmail: true,
    dailyEmail: true,
    defaultFlowhallId: 'welcome-to-centered',
    musicProvider: 'centered',
    hasCompleted_v_4_0_Onboarding: false,
    hasCompleted_v_5_0_Onboarding: false,
    hasBeenSuggestedGCalIntegration: false,
    hasPublicProfile: false,
    stayInBreakWhenIdle: true,
    showFriendsFlowingBadge: true,
    showMusicControlsInPlayer: true,
    launchOnStartup: true,
    lastTab: 'explore',
    suggestedSessionStartHour: 9,
    suggestedSessionEndHour: 17,
    promptCameraOnFlowStart: true,
    discordIntegration: false,
    sessionReminderEmail: true,
    sessionReminderTime: '10:00',
    weeklySessionReminders: [false, false, false, false, false, false, false],
    hasTaskEstimates: true,
    enableBackgroundRotation: true,
    lastGroupMembershipRank: lexorank_1.LexoRank.middle().toString(),
    calendarShowGoogleLinkHidden: false,
    calendarNumberOfDays: 3,
    calendarShowBuddySessions: true,
    showChatPanel: true,
    showCoachPanel: true,
    promptShareTaskToGroup: true,
};
