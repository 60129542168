"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.openIntercomMessenger = exports.disableIntercom = exports.hideIntercomMessengerIconAndUpdateIntercom = exports.hideIntercomMessengerIcon = exports.setupIntercomSettings = void 0;
var baseIntercomSettings = {
    alignment: 'right',
    background_color: '#D2116B',
    action_color: '#D2116B',
    hide_default_launcher: false,
    horizontal_padding: 20,
};
function setupIntercomSettings() {
    // @ts-expect-error globalThis is not typed
    globalThis.intercomSettings = __assign(__assign({}, baseIntercomSettings), { vertical_padding: 120 });
    // @ts-expect-error window.Intercom is not typed
    if (typeof window !== undefined && window.Intercom) {
        // @ts-expect-error window.Intercom is not typed
        window.Intercom('update', { hide_default_launcher: false });
    }
}
exports.setupIntercomSettings = setupIntercomSettings;
function hideIntercomMessengerIcon() {
    // @ts-expect-error globalThis is not typed
    globalThis.intercomSettings = __assign(__assign({}, baseIntercomSettings), { hide_default_launcher: true });
}
exports.hideIntercomMessengerIcon = hideIntercomMessengerIcon;
function hideIntercomMessengerIconAndUpdateIntercom() {
    hideIntercomMessengerIcon();
    // @ts-expect-error window.Intercom is not typed
    if (typeof window !== undefined && window.Intercom) {
        // @ts-expect-error window.Intercom is not typed
        window.Intercom('update', { hide_default_launcher: true });
    }
}
exports.hideIntercomMessengerIconAndUpdateIntercom = hideIntercomMessengerIconAndUpdateIntercom;
function disableIntercom() {
    // @ts-expect-error window.Intercom is not typed
    if (typeof window !== undefined && window.Intercom) {
        // @ts-expect-error window.Intercom is not typed
        window.Intercom('shutdown');
    }
}
exports.disableIntercom = disableIntercom;
function openIntercomMessenger() {
    // @ts-expect-error window.Intercom is not typed
    if (typeof window !== undefined && window.Intercom) {
        // @ts-expect-error window.Intercom is not typed
        window.Intercom('update', __assign(__assign({}, baseIntercomSettings), { alignment: 'right', hide_default_launcher: false }));
        // @ts-expect-error window.Intercom is not typed
        window.Intercom('show');
        // @ts-expect-error window.Intercom is not typed
        window.Intercom('onHide', function () {
            // Close the messenger icon after the messenger closes
            setTimeout(function () {
                // @ts-expect-error window.Intercom is not typed
                window.Intercom('update', {
                    hide_default_launcher: true,
                });
            }, 300);
        });
    }
}
exports.openIntercomMessenger = openIntercomMessenger;
